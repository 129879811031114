import { useEffect, useState, useCallback } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import Navbar from "./navbar";
import ReCAPTCHA from "react-google-recaptcha";
import IconExpand from "./assets/expand-arrow-30.png";
import IconWarnig from "./assets/exclamation-triangle-fill.png";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ApiUrl } from "./ApiUrl";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import Loader from "./loader";

const ClientForm = ({ isDarkMode, setIsDarkMode }) => {
  const [accordianShow, setAccordianShow] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();
  const [clientData, setClientData] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [oldFiles, setOldFiles] = useState(null);
  const [fileState, setFileState] = useState();

  // useLocation gives you access to the current URL location
  const location = useLocation();

  // Check if the token exists in local storage
  const [token, setToken] = useState(localStorage.getItem("token"));
  const userType = localStorage.getItem("userType");
  const { language } = useLanguage();
  const pageTranslations = translations["forms"];
  const [isLoading, setIsLoading] = useState(false);
  const [expire, setExpire] = useState(null);
  const urlPath = window.location.href;
  const currentDate = new Date();
  //Files Drop
  // const onDrop = useCallback(acceptedFiles => {
  //     // Do something with the files
  // }, [])
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  // Get the value of the 'reset_token' query parameter
  const queryParams = new URLSearchParams(location.search);
  const parent_id = queryParams.get("parent_id");

  useEffect(() => {
    // If the token exists, redirect to "/event-portal"
    if (!token) {
      navigate("/", { state: { parent_id: parent_id } });
      // navigate('/?parent_id=' + parent_id);
    } else {
      //     console.log('usertype clientForm', userType)
      if (userType === "client") {
        // navigate('/client-form')
      } else if (userType === "admin") {
        navigate("/event-portal", { replace: true });
      } else {
        toast.error("Something went wrong!");
        navigate("/", { replace: true });
      }
    }
  }, [token, userType]);

  // useEffect(() => {
  //   if (token) {
  //     const fetchClientData = async () => {
  //       try {
  //         const res = await fetch(ApiUrl + "/api/user/get-childform", {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({
  //             parent_id: parent_id,
  //           }),
  //         });
  //         // console.log('Res:', res);
  //         const data = await res.json();
  //         if (data.status === "success") {
  //           setExpire(
  //             urlPath &&
  //               urlPath.includes("parent_id") &&
  //               data.client_form_data[0].first_day
  //               ? currentDate < new Date(data.client_form_data[0].first_day)
  //                 ? false
  //                 : true
  //               : false
  //           );

  //           setOldFiles(data.docs_list);
  //           if (data.submition_check && data.submition_check.length === 0) {
  //             setClientData({
  //               ...data.client_form_data[0]
  //             });
  //             const data2 = {
  //               ...data.parent_form_data[0],
  //               id: data.client_form_data[0]?.id,
  //               parent_id: data.client_form_data[0]?.parent_id,
  //               additional_information:
  //                 data.client_form_data[0]?.additional_information,
  //               client_id: data.client_form_data[0]?.client_id,
  //               dealershipName: data.client_form_data[0]?.dealershipName,
  //               dealership_employee:
  //                 data.client_form_data[0]?.dealership_employee,
  //               due_date: data.client_form_data[0]?.due_date,
  //               event_title: data.client_form_data[0]?.event_title,
  //               invitation_accepted:
  //                 data.client_form_data[0]?.invitation_accepted,
  //               postal_quantity: data.client_form_data[0]?.postal_quantity,
  //               promotions_to_highlight:
  //                 data.client_form_data[0]?.promotions_to_highlight,
  //               submitted_on: data.client_form_data[0]?.submitted_on,
  //               your_featured_vehicle:
  //                 data.client_form_data[0]?.your_featured_vehicle,
  //             };

  //             if (data2 !== undefined && data2 != null) {
  //               for (const key in data2) {
  //                 if (data2[key] === null || data2[key] === "null") {
  //                   data2[key] = "";
  //                 }
  //               }

  //             }

  //             setFormValues({
  //               ...data2,
  //               files: [],
  //               recapchaVal: "",
  //             });
  //           } else {
  //             setClientData(data.client_form_data[0]);
  //             const data2 = data.client_form_data[0];

  //             if (data.client_form_data[0] !== undefined && data.client_form_data[0] != null) {
  //               for (const key in data2) {
  //                 if (data2[key] === null || data2[key] === "null") {
  //                   data2[key] = "";
  //                 }
  //               }

  //             }

  //             setFormValues({
  //               ...data2,
  //               files: [],
  //               recapchaVal: "",
  //             });
  //           }
  //         } else {
  //           toast.error(data.message);
  //         }
  //       } catch (error) {
  //         toast.error("Something went wrong!");
  //       }
  //     };

  //     fetchClientData();
  //   }
  // }, [location]);

  const handleSubmit = async (values, { setValues }) => {
    const formData = new FormData();

    // Append each file to the FormData object
    values.files.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });
    Object.keys(values).forEach((key) => {
      // Exclude the 'files' key as it was handled separately
      if (key === "submitted_on" && values[key] === null) {
        formData.append("invittion_id", 1);
      }
      if (key !== "files") {
        formData.append(key, values[key]);
      }
      if (key === "recapchaVal") formData.delete(key);
    });

    // console.log(values);

    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/add-childform-data", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      setFormValues(values);
      const data = await res.json();
      if (data.status === "success") {
        setIsLoading(false);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
      // console.log(error)
    }
  };

  return (
    <div>
      <div className="client-form">
        <Navbar
          setToken={setToken}
          token={token}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
        />

        {!isLoading && clientData ? (
          <>
            {token && !isLoading && clientData ? (
              <div className="client-form-parent">
                {urlPath && urlPath.includes("parent_id") && (
                  <button
                    type="button"
                    class="btn btn-light "
                    onClick={() => navigate("/client-dashboard")}
                  >
                    <i class="bi bi-arrow-left-circle"></i>{" "}
                    {translations.viewClientForm.formListBtn[language]}
                  </button>
                )}
                {!expire ? (
                  <Formik
                    initialValues={formValues}
                    // initialValues={{
                    //     id: 0,
                    //     name: "",
                    //     dealer: 0,
                    //     week: 0,
                    //     package: "",
                    //     sales_representative: "",
                    //     event_title: "",
                    //     digital: "",
                    //     last_sale_date: "",
                    //     postal_quantity: 0,
                    //     event_dates: "",
                    //     days: 0,
                    //     first_day: "",
                    //     target_age: "",
                    //     postal_code: 0,
                    //     regional_codes: 0,
                    //     to_order: "",
                    //     number_of_postal_sends: 0,
                    //     access_number: 0,
                    //     download_key: "",
                    //     targeted_income: 0,
                    //     sale_title: "",
                    //     featured_vehicle: "",
                    //     favorites: "",
                    //     visual_language: "",
                    //     targeted_territory: "",
                    //     receptionist: "",
                    //     greets_customers: "",
                    //     assignment_by_seller: "",
                    //     new_purchase_or_lease: "",
                    //     opening_hours: 0,
                    //     appointments_per_time_slot: 0,
                    //     appointment_time_slots: 0,
                    //     balloons_colors: "",
                    //     balloons_quantity: 0,
                    //     representatives: "",
                    //     contact_list_phone: "",
                    //     contact_list_email: "",
                    //     contact_list_name: "",
                    //     contact_tp_phone: "",
                    //     contact_tp_email: "",
                    //     contact_tp_name: "",
                    //     comments: "",
                    //     promotions: "",
                    //     directors: "",
                    //     prioriti_reason: "",
                    //     models_or_years_selection: "",
                    //     modifications_instructions: "",
                    // }}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, values, setValues, setFieldValue }) => (
                      <Form className="cform">
                        {/* {()()} */}
                        <div className="client-notification">
                          {translations.viewClientForm.message1[language]}
                          <strong> {values.due_date}</strong>
                        </div>
                        <h2>
                          {
                            translations.viewClientForm.generalInfo.title[
                              language
                            ]
                          }
                        </h2>
                        <div className="client-form-row">
                          <div className="info-column">
                            <label htmlFor="id" className="form-label">
                              ID
                            </label>
                            <br />
                            <Field
                              type="text"
                              name="id"
                              disabled={!expire}
                              style={{
                                backgroundColor: !expire ? "#ced4da66" : "",
                              }}
                            />
                            <ErrorMessage
                              name="id"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                          <div className="info-column">
                            <label
                              htmlFor="dealershipName"
                              className="form-label"
                            >
                              {translations.commonWords.dealer[language]}
                            </label>
                            <br />
                            <Field
                              type="text"
                              name="dealershipName"
                              disabled={!expire}
                              style={{
                                backgroundColor: !expire ? "#ced4da66" : "",
                              }}
                            />
                            <ErrorMessage
                              name="dealershipName"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                          <div className="info-column">
                            <label
                              htmlFor="sales_representative"
                              className="form-label"
                            >
                              {
                                translations.forms.formFields.teamSection
                                  .representative[language]
                              }
                            </label>
                            <br />
                            <Field
                              type="text"
                              name="sales_representative"
                              disabled={!expire}
                              style={{
                                backgroundColor: !expire ? "#ced4da66" : "",
                              }}
                            />
                            <ErrorMessage
                              name="sales_representative"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <div className="client-form-row">
                          <div className="info-column">
                            <div className="client-tooltip">
                              <label htmlFor="new_purchase_or_lease">
                                {
                                  translations.forms.formFields.salesInfoSection
                                    .buyerOfUsedVehicles[language]
                                }
                              </label>
                              <span className="client-tooltiptext">
                                {
                                  translations.forms.tooltip
                                    .buyerOfUsedVehicles[language]
                                }
                              </span>
                            </div>

                            <br />
                            <Field
                              as="select"
                              name="new_purchase_or_lease"
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option value="" selected="">
                                {translations.dropdown.select[language]}
                              </option>
                              <option value="Yes">
                                {translations.dropdown.yes[language]}
                              </option>
                              <option value="No">
                                {translations.dropdown.no[language]}
                              </option>
                            </Field>
                            <ErrorMessage
                              name="new_purchase_or_lease"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                          <div className="info-column">
                            <div className="client-tooltip">
                              <label htmlFor="assignment_by_seller">
                                {
                                  translations.forms.formFields.salesInfoSection
                                    .appointmentABS[language]
                                }
                              </label>
                              <span className="client-tooltiptext">
                                {
                                  translations.forms.tooltip.appointmentABS[
                                    language
                                  ]
                                }
                              </span>
                            </div>

                            <br />
                            <Field
                              as="select"
                              name="assignment_by_seller"
                              className="form-select"
                              aria-label="Default select example"
                              id="ass-rdv"
                            >
                              <option value="" selected="">
                                {translations.dropdown.select[language]}
                              </option>
                              <option value="Assignment">
                                {
                                  translations.forms.formFields.salesInfoSection
                                    .assignment[language]
                                }
                              </option>
                              <option value="No Assignment">
                                {
                                  translations.forms.formFields.salesInfoSection
                                    .noAssignment[language]
                                }
                              </option>
                            </Field>
                            <ErrorMessage
                              name="assignment_by_seller"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <h2>Dates</h2>
                        <div className="client-form-row">
                          <div className="info-column">
                            <div /*classname="mb-3"*/>
                              <label htmlFor="first_day" title="">
                                {
                                  translations.forms.formFields.datesSection
                                    .first_day[language]
                                }
                              </label>
                              <Field
                                value={
                                  values.first_day
                                    ? new Date(values.first_day)
                                        .toISOString()
                                        .split("T")[0]
                                    : ""
                                }
                                type="text"
                                name="first_day"
                                disabled={!expire}
                                style={{
                                  backgroundColor: !expire ? "#ced4da66" : "",
                                }}
                              />
                              <ErrorMessage
                                name="first_day"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="info-column">
                            <div className="mb-3">
                              <label
                                htmlFor="days"
                                data-bs-toggle="tooltip"
                                title="" /*class="form-label"*/
                              >
                                {
                                  translations.forms.formFields.datesSection
                                    .totalNumDays[language]
                                }
                              </label>
                              <Field
                                as="select"
                                name="days"
                                className="form-select"
                                aria-label="Default select example"
                                id="nb_day_1"
                                readOnly
                                disabled
                              >
                                <option value="" selected="">
                                  {
                                    translations.forms.formFields.datesSection
                                      .numDays[language]
                                  }
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                              </Field>
                              <ErrorMessage
                                name="days"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="info-column">
                            <div /*class="mb-3"*/>
                              <label
                                htmlFor="event_dates"
                                data-bs-toggle="tooltip"
                                title="" /*class="form-label"*/
                              >
                                {
                                  translations.viewClientForm.dates.eventDays[
                                    language
                                  ]
                                }
                              </label>
                              <Field
                                as="textarea"
                                name="event_dates"
                                /*class="form-control"*/ id="date_event_1"
                                rows="2"
                                disabled={!expire}
                                style={{
                                  backgroundColor: !expire ? "#ced4da66" : "",
                                }}
                              />
                              <ErrorMessage
                                name="event_dates"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <h2>
                          {
                            translations.viewClientForm.callCenter.title[
                              language
                            ]
                          }
                        </h2>
                        <div className="client-form-row">
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="appointment_time_slots"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {
                                    translations.viewClientForm.callCenter
                                      .durationOfSlots[language]
                                  }
                                </label>

                                <span className="client-tooltiptext">
                                  {
                                    translations.viewClientForm.callCenter.tip1[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                as="select"
                                name="appointment_time_slots"
                                className="form-select"
                                aria-label="Default select example"
                                id="plage-hor-des-rdv"
                                fdprocessedid="1riyds"
                              >
                                <option value="" selected="">
                                  {translations.dropdown.select[language]}
                                </option>
                                <option value="30">30</option>
                                <option value="60">60</option>
                                {/* <option value="90">90</option> */}
                                <option value="120">120</option>
                              </Field>
                              <ErrorMessage
                                name="appointment_time_slots"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>

                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="appointments_per_time_slot"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {
                                    translations.forms.formFields
                                      .salesInfoSection.appointmentsPTS[
                                      language
                                    ]
                                  }
                                </label>

                                <span className="client-tooltiptext">
                                  {
                                    translations.forms.tooltip.appointmentsPTS[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                as="select"
                                name="appointments_per_time_slot"
                                className="form-select"
                                aria-label="Default select example"
                                id="rdv-par-plage-hor"
                                fdprocessedid="t0suu"
                              >
                                <option value="" selected="">
                                  {translations.dropdown.select[language]}
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                
                              </Field>
                              <ErrorMessage
                                name="appointments_per_time_slot"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="opening_hours"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {
                                    translations.forms.formFields
                                      .salesInfoSection.openingHoursWLA[
                                      language
                                    ]
                                  }
                                </label>

                                <span className="client-tooltiptext">
                                  {
                                    translations.forms.tooltip.openingHoursWLA[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                name="opening_hours"
                                type="text"
                                /*class="form-control"*/ id="heures-ouverture"
                                fdprocessedid="sprd8c"
                              />
                              <ErrorMessage
                                name="opening_hours"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="client-form-row">
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="greets_customers"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {
                                    translations.viewClientForm.callCenter
                                      .guidingClients[language]
                                  }
                                </label>

                                <span className="client-tooltiptext">
                                  {
                                    translations.viewClientForm.callCenter.tip4[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                as="select"
                                name="greets_customers"
                                className="form-select"
                                aria-label="Default select example"
                                id="accueille-les-clients"
                                fdprocessedid="k8itlb"
                              >
                                <option value="" selected="">
                                  {translations.dropdown.select[language]}
                                </option>
                                <option value="Event Director">
                                  Event Director
                                </option>
                                <option value="Dealership Employee">
                                  Dealership Employee
                                </option>
                                <option value="Reception">Reception</option>
                              </Field>
                              <ErrorMessage
                                name="greets_customers"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="receptionist"
                                  data-bs-toggle="tooltip" /*class="form-label form-label-sm"*/
                                >
                                  {
                                    translations.viewClientForm.callCenter
                                      .dealershipEmployee[language]
                                  }
                                </label>

                                <span className="client-tooltiptext">
                                  {
                                    translations.viewClientForm.callCenter.tip5[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                name="receptionist"
                                type="text" /*class="form-control"*/
                              />
                              <ErrorMessage
                                name="receptionist"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <h2>Contacts</h2>
                        <h5>
                          {
                            translations.forms.formFields.contactSection
                              .themePromos[language]
                          }
                        </h5>
                        <div className="client-form-row">
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="contact_tp_name"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {translations.commonFields.name[language]}
                                </label>

                                <span className="client-tooltiptext">
                                  {
                                    translations.forms.tooltip.themePromosName[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                name="contact_tp_name"
                                type="text" /*class="form-control"*/
                              />
                              <ErrorMessage
                                name="contact_tp_name"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="contact_tp_email"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {translations.commonFields.email[language]}
                                </label>

                                <span className="client-tooltiptext">
                                  {
                                    translations.forms.tooltip.themePromosEmail[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                name="contact_tp_email"
                                type="text" /*class="form-control"*/
                              />
                              <ErrorMessage
                                name="contact_tp_email"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="contact_tp_phone"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {translations.commonFields.phone[language]}
                                </label>
                                <span className="client-tooltiptext">
                                  {
                                    translations.forms.tooltip.themePromosPhone[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                name="contact_tp_phone"
                                type="text" /*class="form-control"*/
                              />
                              <ErrorMessage
                                name="contact_tp_phone"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <h5>Person in Charge | Lists</h5>
                        <div className="client-form-row">
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="contact_list_name"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {translations.commonFields.name[language]}
                                </label>

                                <span className="client-tooltiptext">
                                  {
                                    translations.forms.tooltip.listsName[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                name="contact_list_name"
                                type="text" /*class="form-control"*/
                              />
                              <ErrorMessage
                                name="contact_list_name"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="contact_list_email"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {translations.commonFields.email[language]}
                                </label>

                                <span className="client-tooltiptext">
                                  {
                                    translations.forms.tooltip.listsEmail[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                name="contact_list_email"
                                type="text" /*class="form-control"*/
                              />
                              <ErrorMessage
                                name="contact_list_email"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="contact_list_phone"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {translations.commonFields.phone[language]}
                                </label>
                                <span className="client-tooltiptext">
                                  {
                                    translations.forms.tooltip.listsPhone[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                name="contact_list_phone"
                                type="text" /*class="form-control"*/
                              />
                              <ErrorMessage
                                name="contact_list_phone"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <h2>
                          {
                            translations.forms.formFields.teamSection.title[
                              language
                            ]
                          }
                        </h2>
                        <div className="client-form-row">
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="directors"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {
                                    translations.forms.formFields
                                      .salesInfoSection.salesdirector[language]
                                  }
                                </label>
                                <span className="client-tooltiptext">
                                  {
                                    translations.forms.tooltip.directors[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                name="directors"
                                type="text" /*class="form-control"*/
                              />
                              <ErrorMessage
                                name="directors"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="info-column">
                            <div>
                              <div className="client-tooltip">
                                <label
                                  htmlFor="representatives"
                                  data-bs-toggle="tooltip" /*class="form-label"*/
                                >
                                  {
                                    translations.forms.clientFormLabels
                                      .representatives[language]
                                  }
                                </label>
                                <span className="client-tooltiptext">
                                  {
                                    translations.forms.tooltip.representatives[
                                      language
                                    ]
                                  }
                                </span>
                              </div>

                              <Field
                                as="textarea"
                                name="representatives"
                                type="text" /*class="form-control"*/
                              />
                              <ErrorMessage
                                name="representatives"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <h2>
                          {translations.viewClientForm.additionalInfo[language]}
                        </h2>
                        <div className="client-form-row">
                          <div className="info-column">
                            <div>
                              <Field
                                as="textarea"
                                name="additional_information"
                                type="text" /*class="form-control"*/
                              />
                              <ErrorMessage
                                name="additional_information"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        {/* --------------------------------------------- */}

                        <div className="client-accordion">
                          <div className="client-accordion-item">
                            <h2
                              className="client-accordion-header"
                              id="headingFour"
                              onClick={() => {
                                accordianShow !== 1
                                  ? setAccordianShow(1)
                                  : setAccordianShow(0);
                              }}
                            >
                              <button
                                className="client-accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="true"
                                aria-controls="collapseFour"
                                fdprocessedid="x8akgr"
                              >
                                <div>
                                  <strong>Balloons – Showroom &nbsp;</strong>
                                  <span className="client-badge">
                                    <img
                                      src={IconWarnig}
                                      style={{ width: 15 }}
                                      alt="Warning icon"
                                    ></img>{" "}
                                    {
                                      translations.viewClientForm.message2[
                                        language
                                      ]
                                    }
                                  </span>
                                  {/* ::after */}
                                </div>
                                <img
                                  className={
                                    accordianShow === 1 || showAll
                                      ? "arrow arrow-rotate"
                                      : "arrow"
                                  }
                                  src={IconExpand}
                                  alt=""
                                />
                              </button>
                            </h2>
                            {(accordianShow === 1 || showAll) && (
                              <div
                                id="collapseFour"
                                className="client-accordion-collapse"
                                aria-labelledby="headingFour"
                                data-bs-parent="#accordionIfApplicable"
                              >
                                <div className="client-accordion-body">
                                  <div className="client-form-row">
                                    <div className="info-column">
                                      <div className="mb-3">
                                        <label
                                          htmlFor="balloons_quantity"
                                          data-bs-toggle="tooltip" /*class="form-label"*/
                                        >
                                          {
                                            translations.viewClientForm
                                              .baloonsAccordian.vehiclesNum[
                                              language
                                            ]
                                          }
                                        </label>

                                        <Field
                                          name="balloons_quantity"
                                          type="text" /*class="form-control"*/
                                        />
                                        <ErrorMessage
                                          name="balloons_quantity"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div className="info-column">
                                      <div className="mb-3">
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="balloons_colors"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.viewClientForm
                                                .baloonsAccordian.desiredColors[
                                                language
                                              ]
                                            }
                                          </label>

                                          <span className="client-tooltiptext">
                                            {
                                              translations.viewClientForm
                                                .baloonsAccordian.tip[language]
                                            }
                                          </span>
                                        </div>
                                        <Field
                                          name="balloons_colors"
                                          type="text" /*class="form-control"*/
                                        />
                                        <ErrorMessage
                                          name="balloons_colors"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* --------------------------------------------- */}

                          <div className="client-accordion-item">
                            <h2
                              className="client-accordion-header"
                              id="headingOne"
                              onClick={() => {
                                accordianShow !== 2
                                  ? setAccordianShow(2)
                                  : setAccordianShow(0);
                              }}
                            >
                              <button
                                className="client-accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                                fdprocessedid="em8y57"
                              >
                                <div>
                                  <strong>
                                    {translations.forms.conquestBtn[language]}{" "}
                                    &nbsp;
                                  </strong>
                                  <span className="client-badge">
                                    <img
                                      src={IconWarnig}
                                      style={{ width: 15 }}
                                      alt="Warning icon"
                                    ></img>{" "}
                                    {
                                      translations.viewClientForm.message2[
                                        language
                                      ]
                                    }
                                  </span>
                                </div>
                                <img
                                  className={
                                    accordianShow === 2 || showAll
                                      ? "arrow arrow-rotate"
                                      : "arrow"
                                  }
                                  src={IconExpand}
                                  alt=""
                                />
                              </button>
                            </h2>
                            {(accordianShow === 2 || showAll) && (
                              <div
                                id="collapseOne"
                                className="client-accordion-collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionIfApplicable"
                              >
                                <div className="client-accordion-body">
                                  <div className="client-form-row">
                                    <div className="info-column">
                                      <div>
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="postal_code"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.forms.formFields
                                                .conquestSection
                                                .targetedPostalCode[language]
                                            }
                                          </label>
                                          <span className="client-tooltiptext">
                                            {
                                              translations.forms.formFields
                                                .conquestSection.postalCode[
                                                language
                                              ]
                                            }
                                          </span>
                                        </div>

                                        <Field
                                          name="postal_code"
                                          type="text"
                                          /*class="form-control"*/ id="zone_cib"
                                        />
                                        <ErrorMessage
                                          name="postal_code"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div className="info-column">
                                      <div>
                                        <label
                                          htmlFor="regional_codes" /*class="form-label"*/
                                        >
                                          {
                                            translations.forms.formFields
                                              .conquestSection.regionalCodes[
                                              language
                                            ]
                                          }
                                        </label>

                                        <Field
                                          name="regional_codes"
                                          type="text"
                                          /*class="form-control"*/ id="indic_reg"
                                        />
                                        <ErrorMessage
                                          name="regional_codes"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div className="info-column">
                                      <div>
                                        <label
                                          htmlFor="targeted_income" /*class="form-label"*/
                                        >
                                          {
                                            translations.forms.formFields
                                              .conquestSection.targetedIncome[
                                              language
                                            ]
                                          }
                                        </label>

                                        <Field
                                          as="select"
                                          name="targeted_income"
                                          className="form-select"
                                          id="rev_cib"
                                        >
                                          <option vlaue="" selected="">
                                            {
                                              translations.dropdown.select[
                                                language
                                              ]
                                            }
                                          </option>
                                          <option value={"25"}>
                                            25k and above
                                          </option>
                                          <option value={"50"}>
                                            50k and above
                                          </option>
                                          <option value={"75"}>
                                            75k and above
                                          </option>
                                          <option value={"100"}>
                                            100k and above
                                          </option>
                                        </Field>
                                        <ErrorMessage
                                          name="targeted_income"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div className="info-column">
                                      <div>
                                        <label
                                          htmlFor="target_age" /*class="form-label"*/
                                        >
                                          {
                                            translations.forms.formFields
                                              .conquestSection.targetAge[
                                              language
                                            ]
                                          }
                                        </label>

                                        <Field
                                          as="select"
                                          name="target_age"
                                          className="form-select"
                                          id="age_cib"
                                        >
                                          <option vlaue="" selected="">
                                            {
                                              translations.dropdown.select[
                                                language
                                              ]
                                            }
                                          </option>
                                          <option value="18 - 65 years">
                                            18 to 65 years
                                          </option>
                                          <option value="35 - 65 years">
                                            35 to 65 years
                                          </option>
                                        </Field>
                                        <ErrorMessage
                                          name="target_age"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div class="client-accordion-item">
                            <h2
                              class="client-accordion-header"
                              id="headingTwo"
                              onClick={() => {
                                accordianShow !== 3
                                  ? setAccordianShow(3)
                                  : setAccordianShow(0);
                              }}
                            >
                              <button
                                class="client-accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                <div>
                                  <strong>
                                    {
                                      translations.viewClientForm
                                        .massivePostalAccordian.title[language]
                                    }{" "}
                                    &nbsp;
                                  </strong>
                                  <span class="client-badge">
                                    <img
                                      src={IconWarnig}
                                      style={{ width: 15 }}
                                      alt="Warning icon"
                                    ></img>{" "}
                                    {
                                      translations.viewClientForm.message2[
                                        language
                                      ]
                                    }
                                  </span>
                                </div>
                                <img
                                  className={
                                    accordianShow === 3 || showAll
                                      ? "arrow arrow-rotate"
                                      : "arrow"
                                  }
                                  src={IconExpand}
                                  alt=""
                                />
                              </button>
                            </h2>
                            {(accordianShow === 3 || showAll) && (
                              <div
                                id="collapseTwo"
                                class="client-accordion-collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionIfApplicable"
                              >
                                <div class="client-accordion-body">
                                  <div class="client-form-row">
                                    <div class="info-column">
                                      <div>
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="postal_quantity"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.viewClientForm
                                                .massivePostalAccordian
                                                .quantity[language]
                                            }
                                          </label>
                                          <span class="client-tooltiptext">
                                            {
                                              translations.viewClientForm
                                                .massivePostalAccordian
                                                .noPostalMailings[language]
                                            }
                                          </span>
                                        </div>

                                        <Field
                                          name="postal_quantity"
                                          type="text" /*class="form-control"*/
                                        />
                                        <ErrorMessage
                                          name="postal_quantity"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div class="info-column">
                                      <div>
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="targeted_territory"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.forms.formFields
                                                .conquestSection
                                                .targetedTerritory[language]
                                            }
                                          </label>
                                          <span class="client-tooltiptext">
                                            {
                                              translations.viewClientForm
                                                .massivePostalAccordian.tip[
                                                language
                                              ]
                                            }
                                          </span>
                                        </div>

                                        <Field
                                          name="targeted_territory"
                                          type="text" /*class="form-control"*/
                                        />
                                        <ErrorMessage
                                          name="targeted_territory"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div class="client-accordion-item">
                            <h2
                              class="client-accordion-header"
                              id="headingThree"
                              onClick={() => {
                                accordianShow !== 4
                                  ? setAccordianShow(4)
                                  : setAccordianShow(0);
                              }}
                            >
                              <button
                                class="client-accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                <div>
                                  <strong>
                                    {
                                      translations.viewClientForm.visualInfo
                                        .title[language]
                                    }{" "}
                                    &nbsp;
                                  </strong>{" "}
                                  <span class="client-badge ">
                                    <img
                                      src={IconWarnig}
                                      style={{ width: 15 }}
                                      alt="Warning icon"
                                    ></img>
                                    {
                                      translations.viewClientForm.message2[
                                        language
                                      ]
                                    }
                                  </span>
                                </div>
                                <img
                                  className={
                                    accordianShow === 4 || showAll
                                      ? "arrow arrow-rotate"
                                      : "arrow"
                                  }
                                  src={IconExpand}
                                  alt=""
                                />
                              </button>
                            </h2>
                            {(accordianShow === 4 || showAll) && (
                              <div
                                id="collapseThree"
                                class="client-accordion-collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionIfApplicable"
                              >
                                <div class="client-accordion-body">
                                  <div class="client-form-row">
                                    <div class="info-column">
                                      <div>
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="event_title"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.viewClientForm
                                                .visualInfo.eventTitle[language]
                                            }{" "}
                                          </label>
                                          <span class="client-tooltiptext">
                                            {
                                              translations.viewClientForm
                                                .visualInfo.tip1[language]
                                            }
                                          </span>
                                        </div>

                                        <Field
                                          name="event_title"
                                          type="text" /*class="form-control"*/
                                        />
                                        <ErrorMessage
                                          name="event_title"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div class="info-column">
                                      <div>
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="featured_vehicle"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.viewClientForm
                                                .visualInfo.featuredVehicle[
                                                language
                                              ]
                                            }
                                          </label>
                                          <span class="client-tooltiptext">
                                            {
                                              translations.viewClientForm
                                                .visualInfo.tip2[language]
                                            }
                                          </span>
                                        </div>

                                        <Field
                                          name="featured_vehicle"
                                          type="text" /*class="form-control"*/
                                        />
                                        <ErrorMessage
                                          name="featured_vehicle"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="client-form-row">
                                    <div class="info-column">
                                      <div /*class="mb-3"*/>
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="your_featured_vehicle"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.viewClientForm
                                                .visualInfo.featuredVehicle[
                                                language
                                              ]
                                            }
                                          </label>

                                          <span class="client-tooltiptext">
                                            {
                                              translations.viewClientForm
                                                .visualInfo.tip3[language]
                                            }
                                          </span>
                                        </div>

                                        <Field
                                          name="your_featured_vehicle"
                                          type="text" /*class="form-control"*/
                                        />
                                        <ErrorMessage
                                          name="your_featured_vehicle"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div class="info-column">
                                      <div>
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="visual_language"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.viewClientForm
                                                .visualInfo.languageOfVisual[
                                                language
                                              ]
                                            }
                                          </label>

                                          <span class="client-tooltiptext">
                                            {
                                              translations.viewClientForm
                                                .visualInfo.languageOfVisual[
                                                language
                                              ]
                                            }
                                          </span>
                                        </div>

                                        <Field
                                          as="select"
                                          name="visual_language"
                                          class="form-select"
                                          id="langue-des-visuelles"
                                        >
                                          <option vlaue="" selected="">
                                            {
                                              translations.dropdown.select[
                                                language
                                              ]
                                            }
                                          </option>
                                          <option value="French">
                                            {
                                              translations.commonWords.french[
                                                language
                                              ]
                                            }{" "}
                                          </option>
                                          <option value="English">
                                            {
                                              translations.commonWords.english[
                                                language
                                              ]
                                            }
                                          </option>
                                          <option value="Bilingual">
                                            {
                                              translations.commonWords.bilangual[
                                                language
                                              ]
                                            }
                                          </option>
                                        </Field>
                                        <ErrorMessage
                                          name="visual_language"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div>
                                      <div className="client-tooltip">
                                        <label
                                          htmlFor="promotions"
                                          data-bs-toggle="tooltip" /*class="form-label"*/
                                        >
                                          {
                                            translations.viewClientForm
                                              .visualInfo.promoHighlights[
                                              language
                                            ]
                                          }
                                        </label>
                                        <span class="client-tooltiptext">
                                          {
                                            translations.viewClientForm
                                              .visualInfo.tip4[language]
                                          }
                                        </span>
                                      </div>

                                      <Field
                                        as="textarea"
                                        name="promotions"
                                        type="text"
                                        /*class="form-control"*/ id="event-promos"
                                        rows="2"
                                      />
                                      <ErrorMessage
                                        name="promotions"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* --------------------------------------------- */}
                        <div
                          /*style={"padding:10px"}*/ class="alert-blue"
                          role="alert"
                        >
                          <div>
                            <h5 /*style={"padding-bottom:10px"}*/>
                              {
                                translations.viewClientForm.usualSelections
                                  .title[language]
                              }
                            </h5>
                            <p style={{ color: "#01B0F0" }}>
                              {
                                translations.viewClientForm.usualSelections
                                  .note[language]
                              }
                            </p>
                            <ul>
                              <li>
                                <strong>
                                  {
                                    translations.viewClientForm.usualSelections
                                      .leaseCustomer.text1[language]
                                  }
                                </strong>{" "}
                                {
                                  translations.viewClientForm.usualSelections
                                    .leaseCustomer.text2[language]
                                }
                              </li>
                              <li>
                                <strong>
                                  {
                                    translations.viewClientForm.usualSelections
                                      .financingCustomer.text1[language]
                                  }
                                </strong>{" "}
                                {
                                  translations.viewClientForm.usualSelections
                                    .financingCustomer.text2[language]
                                }
                              </li>
                              <li>
                                <strong>
                                  {
                                    translations.viewClientForm.usualSelections
                                      .serviceCustomer.text1[language]
                                  }
                                </strong>{" "}
                                {
                                  translations.viewClientForm.usualSelections
                                    .serviceCustomer.text2[language]
                                }
                              </li>
                            </ul>
                            <div class="client-form-row">
                              <div class="info-column">
                                <div /*class="mb-3"*/>
                                  <label
                                    for="modifications_instructions"
                                    data-bs-toggle="tooltip"
                                    title="" /*class="form-label"*/
                                  >
                                    {
                                      translations.viewClientForm
                                        .usualSelections.makeChanges[language]
                                    }
                                  </label>
                                  <Field
                                    as="textarea"
                                    name="modifications_instructions"
                                    type="text"
                                    /*class="form-control"*/ id="modifSelection"
                                  />
                                  <ErrorMessage
                                    name="modifications_instructions"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="client-form-row d-flex align-items-center ">
                              <div class="col-2-3rd">
                                <div>
                                  <div className="client-tooltip">
                                    <label
                                      for="models_or_years_selection"
                                      data-bs-toggle="tooltip" /*class="form-label"*/
                                    >
                                      {
                                        translations.viewClientForm
                                          .usualSelections.specificModels[
                                          language
                                        ]
                                      }
                                    </label>
                                    <span class="client-tooltiptext">
                                      {
                                        translations.viewClientForm
                                          .usualSelections.tip1[language]
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-1-3rd">
                                <div>
                                  <Field
                                    as="select"
                                    name="models_or_years_selection"
                                    class="form-select"
                                    aria-label="Default select example"
                                    id="modeleAnnPrio"
                                  >
                                    <option vlaue="" selected="">
                                      {translations.dropdown.select[language]}
                                    </option>
                                    <option value="Yes">
                                      {translations.dropdown.yes[language]}
                                    </option>
                                    <option value="No">
                                      {translations.dropdown.no[language]}
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="models_or_years_selection"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              {values.models_or_years_selection === "Yes" && (
                                <div>
                                  <div>
                                    <label
                                      for="prioriti_reason"
                                      data-bs-toggle="tooltip"
                                      title="" /*class="form-label"*/
                                    >
                                      {
                                        translations.viewClientForm
                                          .usualSelections.iFYes[language]
                                      }
                                    </label>
                                    <Field
                                      as="textarea"
                                      name="prioriti_reason"
                                      type="text"
                                      /*class="form-control"*/ id="modeleAnnPrioOui"
                                    />
                                    <ErrorMessage
                                      name="prioriti_reason"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              )}
                              <p
                                style={{
                                  fontSize: 20,
                                  textAlign: "center",
                                  marginBottom: 12,
                                }}
                              >
                                <strong>
                                  {
                                    translations.viewClientForm.usualSelections
                                      .purification[language]
                                  }
                                </strong>
                              </p>
                            </div>
                          </div>

                          <div className="clientMessage">
                            <div className="client-form-row">
                              <div className="col-2-3rd">
                                <h4>
                                  {
                                    translations.viewClientForm.impDocs.title[
                                      language
                                    ]
                                  }
                                </h4>
                              </div>
                              <div className="col-1-3rd">
                                <div className="document-btn">
                                  <button
                                    className="client-btn"
                                    /* btn-primary"*/ type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseExample"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                  >
                                    {
                                      translations.viewClientForm.impDocs
                                        .learnBtn[language]
                                    }
                                  </button>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div class="collapse" id="collapseExample">
                              <div className="client-card">
                                <h5>
                                  {
                                    translations.viewClientForm.impDocs
                                      .telephony[language]
                                  }
                                </h5>
                                <p>
                                  <span className="text-red">
                                    {
                                      translations.viewClientForm.impDocs.imp[
                                        language
                                      ]
                                    }
                                  </span>{" "}
                                  {
                                    translations.viewClientForm.impDocs.note1
                                      .txt1[language]
                                  }{" "}
                                  <span style={{ color: "#008000" }}>
                                    {values.due_date}
                                  </span>{" "}
                                  {
                                    translations.viewClientForm.impDocs.note1
                                      .txt2[language]
                                  }
                                </p>
                                <p>
                                  1.{" "}
                                  <strong>
                                    {
                                      translations.viewClientForm.impDocs
                                        .singleList.title[language]
                                    }
                                    :
                                  </strong>{" "}
                                  {
                                    translations.viewClientForm.impDocs
                                      .singleList.txt1[language]
                                  }
                                </p>
                                <p>
                                  2.{" "}
                                  <strong>
                                    {
                                      translations.viewClientForm.impDocs
                                        .separateList.txt1[language]
                                    }
                                  </strong>{" "}
                                  {
                                    translations.viewClientForm.impDocs
                                      .separateList.txt2[language]
                                  }
                                </p>
                                <div className="client-form-row">
                                  <div className="info-column">
                                    <p>
                                      <span className="text-red">
                                        {
                                          translations.viewClientForm.impDocs
                                            .imp[language]
                                        }
                                      </span>{" "}
                                      {
                                        translations.viewClientForm.impDocs
                                          .essentialColumns.text1[language]
                                      }
                                      <br />{" "}
                                      {
                                        translations.viewClientForm.impDocs
                                          .essentialColumns.text2[language]
                                      }
                                    </p>
                                    <ul>
                                      <li>
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.name[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li>
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.address[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li>
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.phones[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li>
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.email[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li>
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.MMY[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li>
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.advName[
                                            language
                                          ]
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="info-column">
                                    <p>
                                      <span className="text-red">
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.also[
                                            language
                                          ]
                                        }
                                      </span>
                                      ,{" "}
                                      {
                                        translations.viewClientForm.impDocs
                                          .essentialColumns.fields.alsoTxt[
                                          language
                                        ]
                                      }
                                    </p>
                                    <div className="client-form-row">
                                      <div className="info-column">
                                        <span className="text-red">
                                          {
                                            translations.viewClientForm
                                              .usualSelections.leaseCustomer
                                              .text1[language]
                                          }
                                        </span>
                                        <ul>
                                          <li>
                                            {
                                              translations.viewClientForm
                                                .impDocs.essentialColumns.fields
                                                .dateOfPayment[language]
                                            }
                                          </li>
                                          <li>
                                            {
                                              translations.viewClientForm
                                                .impDocs.essentialColumns.fields
                                                .purchaseDate[language]
                                            }
                                          </li>
                                        </ul>
                                        <span className="text-red">
                                          {
                                            translations.viewClientForm
                                              .usualSelections.financingCustomer
                                              .text1[language]
                                          }
                                        </span>
                                        <ul>
                                          <li>
                                            {
                                              translations.viewClientForm
                                                .impDocs.essentialColumns.fields
                                                .dateOfPaymentterm[language]
                                            }
                                          </li>
                                          <li>
                                            {
                                              translations.viewClientForm
                                                .impDocs.essentialColumns.fields
                                                .purchaseDate[language]
                                            }
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="info-column">
                                        <span className="text-red">
                                          {
                                            translations.viewClientForm
                                              .usualSelections.serviceCustomer
                                              .text1[language]
                                          }
                                        </span>
                                        <ul>
                                          <li>
                                            {
                                              translations.viewClientForm
                                                .impDocs.essentialColumns.fields
                                                .dateOfPaymentService[language]
                                            }
                                          </li>
                                          <li>
                                            {
                                              translations.viewClientForm
                                                .impDocs.essentialColumns.fields
                                                .purchaseDate[language]
                                            }
                                          </li>
                                        </ul>
                                        <span className="text-red">
                                          {
                                            translations.viewClientForm.impDocs
                                              .essentialColumns.fields.soldIn24[
                                              language
                                            ]
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <h5>
                                    {
                                      translations.viewClientForm.impDocs
                                        .essentialColumns.logo.title[language]
                                    }
                                  </h5>
                                  <p>
                                    {
                                      translations.viewClientForm.impDocs
                                        .essentialColumns.logo.txt1[language]
                                    }{" "}
                                    <strong>{values.due_date}</strong>.{" "}
                                    {
                                      translations.viewClientForm.impDocs
                                        .essentialColumns.logo.txt2[language]
                                    }{" "}
                                    <strong>{values.due_date}</strong>.{" "}
                                    {
                                      translations.viewClientForm.impDocs
                                        .essentialColumns.logo.txt3[language]
                                    }
                                  </p>
                                  <div style={{ paddingTop: 25 }}></div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="client-card">
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                // console.log(acceptedFiles)
                                if (acceptedFiles.length > 0) {
                                  setFieldValue("files", [
                                    ...values.files,
                                    ...acceptedFiles,
                                  ]);
                                }
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="client-form-row">
                                  <div style={{ marginBottom: 16 }}>
                                    <p>
                                      {
                                        translations.viewClientForm
                                          .submitSection.text1[language]
                                      }
                                    </p>
                                    <label
                                      htmlFor="files" /*class="form-label"*/
                                    >
                                      {
                                        translations.viewClientForm
                                          .submitSection.selectedFiles[language]
                                      }
                                    </label>

                                    <div
                                      {...getRootProps()}
                                      style={{
                                        background: "#E7ECEF",
                                        padding: "20px 0",
                                        borderRadius: "0.375rem",
                                      }}
                                    >
                                      <input {...getInputProps()} type="file" />

                                      <div className="client-file-input">
                                        <p style={{ textAlign: "center" }}>
                                          {
                                            translations.viewClientForm
                                              .submitSection.DnD[language]
                                          }
                                        </p>
                                        {/* <input
                                                                    type="file"
                                                                    id="files"
                                                                    multiple
                                                                    value={values.files}
                                                                    disabled
                                                                    // onChange={(e) => {
                                                                    //     setValues({ ...values, files: [...e.target.files] })
                                                                    // }}
                                                                /> */}
                                        {/* {console.log(values.files)} */}
                                      </div>
                                      <p className="warning">
                                        {
                                          translations.viewClientForm
                                            .submitSection.fileSize[language]
                                        }
                                      </p>
                                    </div>
                                    <ErrorMessage
                                      name="files"
                                      component="div"
                                      className="error text-danger"
                                    />

                                    {oldFiles &&
                                      oldFiles.length > 0 &&
                                      oldFiles.map((file, i) => (
                                        <div className="d-flex gap-3 pt-1">
                                          <p>{file.type}</p>
                                        </div>
                                      ))}

                                    {values.files &&
                                      values.files.length > 0 && (
                                        <p className="py-2 fw-bold ">
                                          Selected Files:
                                        </p>
                                      )}

                                    {values.files &&
                                      values.files.length > 0 &&
                                      values.files.map((file, i) => (
                                        <div className="d-flex gap-3">
                                          <p>{file.name}</p>
                                          <i
                                            class="bi bi-trash"
                                            onClick={() =>
                                              setValues({
                                                ...values,
                                                files: values.files.filter(
                                                  (item, ind) => ind !== i
                                                ),
                                              })
                                            }
                                          ></i>
                                        </div>
                                      ))}
                                    {/* <Field type="hidden" id="fileData" name="fileData" />
                                                <Field type="hidden" id="mimeType" name="mimeType" />
                                                <Field type="hidden" id="fileName" name="fileName" /> */}
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                          <ReCAPTCHA
                            name="recaptcha"
                            sitekey="6Ld7dhkjAAAAANUYYEVWh9pFiaqrxtYLC2bzF2Sa"
                            onChange={(val) => {
                              setValues({ ...values, recapchaVal: val });
                            }}
                          ></ReCAPTCHA>
                          <ErrorMessage
                            name="recapchaVal"
                            component="div"
                            className="error text-danger"
                          />
                          {/* <div id="recaptcha" class="g-recaptcha" data-sitekey="6Lcor4EhAAAAAOhhlB--KaLKIz1Aa15l4egtdiMW">
                            <div style="width: 304px; height: 78px;">
                                <div>
                                    <iframe title="reCAPTCHA" width="304" height="78" role="presentation" name="a-y0zbd8dy84el" frameborder="0" scrolling="no"
                                        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                                        src="https://www.google.com/recaptcha/api2/anchor?ar=2&amp;k=6Lcor4EhAAAAAOhhlB--KaLKIz1Aa15l4egtdiMW&amp;co=aHR0cHM6Ly9uLXJyNW83NjN3bmV5eG9haGZtMmFjNHQzZHlyeTd3bWpjNWpwcWZwcS0wbHUtc2NyaXB0Lmdvb2dsZXVzZXJjb250ZW50LmNvbTo0NDM.&amp;hl=en&amp;v=cwQvQhsy4_nYdnSDY4u7O5_B&amp;size=normal&amp;cb=l9uhpdelio4h">
                                    </iframe>
                                </div>
                                <textarea id="g-recaptcha-response" name="g-recaptcha-response" class="g-recaptcha-response"
                                    style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;">
                                </textarea>
                            </div>
                            <iframe style="display: none;">
                            </iframe>
                        </div> */}
                        </div>
                        <button
                          className="client-btn"
                          id="save-changes-v2"
                          type="submit"
                          value="Soumettre"
                          onClick={() => {
                            let stopLoop = false;
                            Object.keys(errors).length > 0 &&
                              Object.keys(errors).some(
                                (field, index, array) => {
                                  if (stopLoop) return;
                                  if (Array.isArray(errors[field])) {
                                    errors[field].forEach((element) => {
                                      toast.error(element);
                                    });
                                  } else {
                                    setShowAll(true);
                                    toast.error(errors[field]);
                                  }
                                  if (index === array.length - 1) {
                                    stopLoop = true;
                                  }
                                  return stopLoop;
                                }
                              );
                          }}
                        >
                          <i class="bi bi-box-arrow-down"></i>{" "}
                          {translations.commonFields.submitBtn[language]}
                        </button>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div className="text-center py-4">
                    <h5 className="text-danger">
                      You have hit the deadline, you cannot submit anymore!{" "}
                    </h5>{" "}
                    <h6 className="text-danger">
                      {" "}
                      The deadline for form submission is:
                      <strong> {formValues.first_day}</strong>
                    </h6>
                  </div>
                )}
              </div>
            ) : (
              <h5 className="text-center text-danger py-4">No Data Found</h5>
            )}
          </>
        ) : (
          <Loader />
        )}
        {/* <p class="footertext">Propulsé par Fidgi Communications.<br /> © 2022 FIDGI Communications. Tous droits réservés.</p> */}
      </div>
    </div>
  );
};

export default ClientForm;
