import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import useDealerships from "./useDealerships";
import ModalAddDealer_CreateEvent from "./ModalAddDealer_CreateEvent";
import { ApiUrl } from "./ApiUrl";
import Select from "react-select";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";

const CreateEvent = ({
  dealerships,
  setDealerTrigger,
  setIsLoading,
  isDarkMode,
}) => {
  const [isOpenAll, setIsOpenAll] = useState(false);
  const [submitPressed, setSubmitPressed] = useState(false);
  const token = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");
  const { language } = useLanguage();
  const pageTranslations = translations["forms"];

  // const [dealerTrigger, setDealerTrigger] = useState(false)
  // Use the custom hook
  // const [dealerships, setDealerships] = useState();
  // const dealershipsHook = useDealerships(token, dealerTrigger);

  // setDealerships(dealershipsHook)

  const handleToggleAll = () => {
    setIsOpenAll((prevIsOpenAll) => !prevIsOpenAll);
  };

  const validationSchema = Yup.object().shape({
    days: Yup.number().required(
      pageTranslations.validationTranslations.days[language]
    ),
    first_day: Yup.date().required(
      pageTranslations.validationTranslations.first_day[language]
    ),
  });

  const handleSubmit = async (values, { resetForm }, error) => {
    // console.log("values: ", values);
    setIsLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/" + userType + "-create-parentform",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(values),
        }
      );
      // console.log("res", res)
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const options = dealerships.map((dealership) => ({
    value: dealership.id,
    label: dealership.name,
  }));

  useEffect(() => {
    document.getElementById("dateBtn").click();
  }, []);

  return (
    <div className="create-event">
      <div class="container maincont">
        <div id="app" className="appcountainer2">
          <Formik
            initialValues={{
              id: "",
              name: "",
              dealer: "",
              week: "",
              package: "",
              sales_representative: "",
              title: "",
              digital: "",
              last_sale_date: "",
              quantity: "",
              event_dates: "",
              event_title: "",
              days: "",
              first_day: "",
              target_age: "",
              postal_code: "",
              regional_codes: "",
              to_order: "",
              number_of_postal_sends: "",
              access_number: "",
              download_key: "",
              targeted_income: "",
              sale_title: "",
              featured_vehicle: "",
              favorites: "",
              visual_language: "",
              targeted_territory: "",
              receptionist: "",
              greets_customers: "",
              assignment_by_seller: "",
              new_purchase_or_lease: "",
              opening_hours: "",
              appointments_per_time_slot: "",
              appointment_time_slots: "",
              balloons_colors: "",
              balloons_quantity: "",
              representatives: "",
              contact_list_phone: "",
              contact_list_email: "",
              contact_list_name: "",
              contact_tp_phone: "",
              contact_tp_email: "",
              contact_tp_name: "",
              comments: "",
              promotions: "",
              directors: "",
              prioriti_reason: "",
              models_or_years_selection: "",
              modifications_instructions: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, setValues }) => (
              <Form>
                {/* Display a toast for each field with an error */}
                {(() => {
                  let stopLoop = false;

                  submitPressed &&
                    Object.keys(errors).length > 0 &&
                    Object.keys(errors).some((field, index, array) => {
                      if (stopLoop) return;
                      toast.error(errors[field]);

                      if (index === array.length - 1) {
                        setSubmitPressed(false);
                        stopLoop = true;
                      }

                      return stopLoop;
                    });
                })()}
                <div class="edit-form-form">
                  <div class="headersec">
                    <nav class="navbar navbar-expand-md navbar-light">
                      <div class="container-fluid">
                        <h2
                          className={`${isDarkMode ? "darkthead" : ""}`}
                        >
                          {pageTranslations.title[language]}
                        </h2>

                        <button
                          class="navbar-toggler"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarNav"
                          aria-controls="navbarNav"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <span class="navbar-toggler-icon"></span>
                        </button>
                        <div
                          class="collapse navbar-collapse justify-content-end"
                          id="navbarNav"
                        >
                          <ul class="navbar-nav">
                            <li class="nav-item">
                              <button
                                id="dateBtn"
                                class="admin-btn btn-primary"
                                type="button"
                                data-bs-toggle="collapse"
                                href="#multiCollapseDate"
                                role="button"
                                aria-expanded="false"
                                aria-controls="multiCollapseDate"
                              >
                                <i class="bi bi-calendar"></i>{" "}
                                {pageTranslations.datesBtn[language]}
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                class="admin-btn btn-primary"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#multiCollapseOptions"
                                aria-expanded="false"
                                aria-controls="multiCollapseOptions"
                              >
                                <i class="bi bi-people-fill"></i>{" "}
                                {pageTranslations.conquestBtn[language]}
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                class="admin-btn btn-primary"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#multiCollapseInfosvente"
                                aria-expanded="false"
                                aria-controls="multiCollapseInfosvente"
                              >
                                <i class="bi bi-info-circle"></i>{" "}
                                {pageTranslations.salesInfoBtn[language]}
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                class="admin-btn btn-primary"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#multiCollapseInfoscontact"
                                aria-expanded="false"
                                aria-controls="multiCollapseInfoscontact"
                              >
                                <i class="bi bi-person-lines-fill"></i>{" "}
                                {pageTranslations.contactBtn[language]}
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                class="admin-btn btn-primary"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#multiCollapseInfosequipe"
                                aria-expanded="false"
                                aria-controls="multiCollapseInfosequipe"
                              >
                                <i class="bi bi-person-badge"></i>{" "}
                                {pageTranslations.teamBtn[language]}
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                class="admin-btn btn-primary"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#multiCollapseInfospromos"
                                aria-expanded="false"
                                aria-controls="multiCollapseInfospromos"
                              >
                                <i class="bi bi-stars"></i>{" "}
                                {pageTranslations.promosBtn[language]}
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                className="admin-btn btn-primary"
                                type="button"
                                onClick={handleToggleAll}
                              >
                                <i className="bi bi-folder2-open"></i>{" "}
                                {isOpenAll
                                  ? pageTranslations.closeAllBtn[language]
                                  : pageTranslations.openAllBtn[language]}
                              </button>
                              {/* <button class="admin-btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseDate multiCollapseOptions multiCollapseInfosvente multiCollapseInfoscontact multiCollapseInfosequipe multiCollapseInfospromos"><i class="bi bi-folder2-open"></i> Open All</button> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div class="bodysectab">
                    <div class="row">
                      <div class="col">
                        <div class="row">
                          <label class="form-label">
                            {pageTranslations.formFields.dealerDrop[language]}
                          </label>
                          <div class="col-8">
                            <div class="mb-3">
                              <Field
                                name="dealer"
                                render={({ field, form }) => (
                                  <Select
                                    className="selectpicker"
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    options={[
                                      {
                                        value: "",
                                        label:
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      },
                                      ...options,
                                    ]}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isSearchable
                                    value={
                                      options.find(
                                        (option) => option.value === field.value
                                      ) || {
                                        value: "",
                                        label:
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      }
                                    }
                                    onChange={(selectedOption) =>
                                      form.setFieldValue(
                                        field.name,
                                        selectedOption.value
                                      )
                                    }
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "white",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".25rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                )}
                              />
                              {/* <Field as="select" class="selectpicker form-select" name="dealer" aria-label="Dealer"
                                id="dealership-name2" data-show-subtext="true" data-live-search="true" fdprocessedid="qo2vc8">
                                <option value="" selected="">Select</option>

                                {dealerships && dealerships.map((dealership) => (
                                  < option key={dealership.id} value={dealership.id}>{dealership.name}</option>
                                ))}
                              </Field> */}
                              <ErrorMessage
                                name="dealer"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="mb-3">
                              <button
                                type="button"
                                class="client-btn btn-success"
                                id="add-dlr-trigger"
                                data-bs-toggle="modal"
                                data-bs-target="#AddDealerModal"
                                fdprocessedid="wqiuds"
                              >
                                <i class="bi bi-plus-circle"></i>{" "}
                                {
                                  translations["quickCreationModal"].addBtn[
                                    language
                                  ]
                                }
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="event-1-Week" class="form-label">
                            {pageTranslations.formFields.week[language]}
                          </label>
                          <Field
                            type="number"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            class="form-control"
                            name="week"
                            id="event-1-Week"
                            fdprocessedid="2r24a"
                          />
                          <ErrorMessage
                            name="week"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="package" class="form-label">
                            {pageTranslations.formFields.package[language]}
                          </label>
                          <Field
                            type="text"
                            name="package"
                            class="form-control"
                            id="package"
                            fdprocessedid="v05rnp"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="package"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label class="form-label">
                            {
                              pageTranslations.formFields.representative[
                                language
                              ]
                            }
                          </label>
                          <Field
                            as="select"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            class={`form-select ${
                              isDarkMode ? "custom-select" : ""
                            }`}
                            name="sales_representative"
                            aria-label={
                              pageTranslations.formFields.representative[
                                language
                              ]
                            }
                            id="rep-name"
                            fdprocessedid="gibsqa"
                          >
                            <option
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              {
                                pageTranslations.formFields.representative[
                                  language
                                ]
                              }
                            </option>
                            <option
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              François Mercier
                            </option>
                            <option
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              Jean-Francois Coulombe
                            </option>
                            <option
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              Serge Beaulé
                            </option>
                            <option
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              Vincent Mercier
                            </option>
                            <option
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              Yves Chiasson
                            </option>
                          </Field>
                          <ErrorMessage
                            name="sales_representative"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="last-sale-date" class="form-label">
                            {pageTranslations.formFields.lastSaleDate[language]}
                          </label>
                          <Field
                            type="date"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            name="last_sale_date"
                            class={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            id="last-sale-date"
                          />
                          <ErrorMessage
                            name="last_sale_date"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.digital[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            as="select"
                            class={`form-select ${
                              isDarkMode ? "custom-select" : ""
                            }`}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            name="digital"
                            aria-label="Digital"
                          >
                            <option
                              value=""
                              selected=""
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              {translations["dropdown"].select[language]}
                            </option>
                            <option
                              value={"Yes"}
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              {translations["dropdown"].yes[language]}
                            </option>
                            <option
                              value={"No"}
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              {translations["dropdown"].no[language]}
                            </option>
                          </Field>
                          <ErrorMessage
                            name="digital"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row align-middle" style={{ paddingBottom: 10 }}>
                      <div
                        class={`collapse multi-collapse ${
                          isOpenAll ? "show" : ""
                        }`}
                        id="multiCollapseDate"
                      >
                        <div
                          class="card card-body"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        >
                          <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                            {
                              pageTranslations.formFields.datesSection.dates[
                                language
                              ]
                            }
                          </h2>
                          <p className="text-danger">
                            {
                              pageTranslations.formFields.datesSection.message[
                                language
                              ]
                            }
                          </p>
                          <div class="row">
                            <div class="col-2 align-middle">
                              <div class="mb-3">
                                <p>
                                  {
                                    pageTranslations.formFields.datesSection
                                      .title[language]
                                  }
                                </p>
                              </div>
                            </div>
                            <div class="col-2">
                              <div class="mb-3">
                                <p>
                                  1
                                  <sup>
                                    {
                                      pageTranslations.formFields.datesSection
                                        .st[language]
                                    }
                                  </sup>{" "}
                                  {
                                    pageTranslations.formFields.datesSection
                                      .day[language]
                                  }
                                </p>
                              </div>
                            </div>

                            <div class="col-1">
                              <div class="mb-3">
                                <p>
                                  {
                                    pageTranslations.formFields.datesSection
                                      .days[language]
                                  }
                                </p>
                              </div>
                            </div>

                            <div class="col-7">
                              <div class="mb-3">
                                <p>
                                  {
                                    pageTranslations.formFields.datesSection
                                      .EventsDates[language]
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="row align-middle">
                            <div class="col-2 align-middle">
                              <div class="mb-3">
                                <p>
                                  1
                                  <sup>
                                    {
                                      pageTranslations.formFields.datesSection
                                        .st[language]
                                    }
                                  </sup>{" "}
                                  {
                                    translations["EventsPortal"].title.events[
                                      language
                                    ]
                                  }
                                </p>
                              </div>
                            </div>

                            <div class="col-2">
                              <div class="mb-3">
                                <Field
                                  type="date"
                                  name="first_day"
                                  onChange={(e) => {
                                    if (values.days && e.target.value) {
                                      let daysValues = "";
                                      for (
                                        let i = 0;
                                        i < Number(values.days);
                                        i++
                                      ) {
                                        const newDate = new Date(
                                          e.target.value
                                        );
                                        newDate.setDate(newDate.getDate() + i);
                                        const formattedDate = newDate
                                          .toISOString()
                                          .split("T")[0];
                                        daysValues = daysValues
                                          ? daysValues + " / " + formattedDate
                                          : formattedDate;
                                      }
                                      setValues({
                                        ...values,
                                        first_day: e.target.value,
                                        event_dates: daysValues,
                                      });
                                    } else {
                                      setValues({
                                        ...values,
                                        first_day: e.target.value,
                                      });
                                    }
                                  }}
                                  class={`form-control ${
                                    isDarkMode ? "custom-date" : ""
                                  }`}
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                  id="date_day_1" /*onchange="datesCalculator()" */
                                />
                                <ErrorMessage
                                  name="first_day"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col-1">
                              <Field
                                as="select"
                                class={`form-select ${
                                  isDarkMode ? "custom-select" : ""
                                }`}
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                name="days"
                                onChange={(e) => {
                                  // console.log(e.target.value);
                                  if (e.target.value && values.first_day) {
                                    let daysValues = "";
                                    for (
                                      let i = 0;
                                      i < Number(e.target.value);
                                      i++
                                    ) {
                                      const newDate = new Date(
                                        values.first_day
                                      );
                                      newDate.setDate(newDate.getDate() + i);
                                      const formattedDate = newDate
                                        .toISOString()
                                        .split("T")[0];
                                      // console.log(formattedDate);
                                      daysValues = daysValues
                                        ? daysValues + " / " + formattedDate
                                        : formattedDate;
                                    }
                                    setValues({
                                      ...values,
                                      days: e.target.value,
                                      event_dates: daysValues,
                                    });
                                  } else {
                                    setValues({
                                      ...values,
                                      days: e.target.value,
                                    });
                                  }
                                }}
                                aria-label="Default select example"
                                id="nb_day_1"
                                /*onchange="datesCalculator()"*/ fdprocessedid="0w4dw"
                              >
                                <option
                                  value=""
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                  selected=""
                                ></option>
                                <option
                                  value="1"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  1
                                </option>
                                <option
                                  value="2"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  2
                                </option>
                                <option
                                  value="3"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  3
                                </option>
                                <option
                                  value="4"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  4
                                </option>
                                <option
                                  value="5"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  5
                                </option>
                                <option
                                  value="6"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  6
                                </option>
                                <option
                                  value="7"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  7
                                </option>
                                <option
                                  value="8"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  8
                                </option>
                                <option
                                  value="9"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  9
                                </option>
                                <option
                                  value="10"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  10
                                </option>
                                <option
                                  value="11"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  11
                                </option>
                                <option
                                  value="12"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  12
                                </option>
                                <option
                                  value="13"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  13
                                </option>
                                <option
                                  value="14"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  14
                                </option>
                                <option
                                  value="15"
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  15
                                </option>
                              </Field>
                              <ErrorMessage
                                name="days"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                            <div class="col-7">
                              <div class="mb-3">
                                <Field
                                  as="textarea"
                                  class="form-control"
                                  name="event_dates"
                                  id="date_event_1"
                                  rows="2"
                                  readonly=""
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                ></Field>
                                <ErrorMessage
                                  name="event_dates"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

{
  false &&

                    <div class="row" style={{ paddingBottom: 10 }}>
                      <div
                        class={`collapse multi-collapse ${
                          isOpenAll ? "show" : ""
                        }`}
                        id="multiCollapseOptions"
                      >
                        <div
                          class="card card-body"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        >
                          <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                            {
                              pageTranslations.formFields.conquestSection.title[
                                language
                              ]
                            }
                          </h2>

                          <div class="row">
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="opt_conq"
                                  data-bs-toggle="tooltip"
                                  title="Reconquest (inactive) or conquest (infocanda)"
                                  class="form-label"
                                >
                                  Type
                                </label>

                                <Field
                                  as="select"
                                  class={`form-select ${
                                    isDarkMode ? "custom-select" : ""
                                  }`}
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                  aria-label="Default select example"
                                  name="type_conquest"
                                  id="opt_conq"
                                >
                                  <option
                                    value="Conquest"
                                    selected=""
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                      pageTranslations.formFields
                                        .conquestSection.title[language]
                                    }
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="type_conquest"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="opt-conquetes-nb-pros"
                                  data-bs-toggle="tooltip"
                                  title="Number of prospects for pure conquest"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.conquestSection
                                      .requiredQty[language]
                                  }
                                </label>
                                <Field
                                  /*onchange="calctotalconquest()"*/ name="quantity"
                                  type="text"
                                  class="form-control"
                                  id="opt-conquetes-nb-pros"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="quantity"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="opt-conquetes-nb-comm"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.conquestSection
                                      .toOrder[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="to_order"
                                  class="form-control"
                                  id="opt-conquetes-nb-comm"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="to_order"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="zone_cib"
                                  data-bs-toggle="tooltip"
                                  title="Postal Code"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.conquestSection
                                      .postalCode[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="postal_code"
                                  class="form-control"
                                  id="zone_cib"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="postal_code"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <div class="mb-3">
                                <label for="indic_reg" class="form-label">
                                  {
                                    pageTranslations.formFields.conquestSection
                                      .regionalCodes[language]
                                  }{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="regional_codes"
                                  class="form-control"
                                  id="indic_reg"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="regional_codes"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <div class="mb-3">
                                <label for="rev_cib" class="form-label">
                                  {
                                    pageTranslations.formFields.conquestSection
                                      .targetedIncome[language]
                                  }{" "}
                                </label>
                                <Field
                                  as="select"
                                  class={`form-select ${
                                    isDarkMode ? "custom-select" : ""
                                  }`}
                                  name="targeted_income"
                                  id="rev_cib"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                >
                                  <option
                                    value=""
                                    selected=""
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].select[language]}
                                  </option>
                                  <option
                                    value={25}
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    25k{" "}
                                    {translations["dropdown"].andMore[language]}
                                  </option>
                                  <option
                                    value={50}
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    50k{" "}
                                    {translations["dropdown"].andMore[language]}
                                  </option>
                                  <option
                                    value={75}
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    75k{" "}
                                    {translations["dropdown"].andMore[language]}
                                  </option>
                                  <option
                                    value={100}
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    100k{" "}
                                    {translations["dropdown"].andMore[language]}
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="targeted_income"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label for="age_cib" class="form-label">
                                  {
                                    pageTranslations.formFields.conquestSection
                                      .targetAge[language]
                                  }{" "}
                                </label>
                                <Field
                                  as="select"
                                  class={`form-select ${
                                    isDarkMode ? "custom-select" : ""
                                  }`}
                                  name="target_age"
                                  id="age_cib"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                >
                                  <option
                                    value=""
                                    selected=""
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].select[language]}
                                  </option>
                                  <option
                                    value="18 - 65 years"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                      translations["dropdown"].year18to65[
                                        language
                                      ]
                                    }
                                  </option>
                                  <option
                                    value="35 - 65 years"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                      translations["dropdown"].year35to65[
                                        language
                                      ]
                                    }
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="target_age"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="lnnte_acc_abb"
                                  data-bs-toggle="tooltip"
                                  title="Number of Postal Sends"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.conquestSection
                                      .subcriptionAN[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="access_number"
                                  class="form-control"
                                  id="lnnte_acc_abb"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="access_number"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="lnnte_acc_key"
                                  data-bs-toggle="tooltip"
                                  title="Number of Postal Sends"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.conquestSection
                                      .downloadKey[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="download_key"
                                  class="form-control"
                                  id="lnnte_acc_key"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="download_key"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            {/* <div class="col-sm mb-2">
                              <button
                                type="submit"
                                class="client-btn btn-primary"
                                id="form-conq"
                              >
                                {
                                  pageTranslations.formFields.conquestSection
                                    .generateFormBtn[language]
                                }
                              </button>
                            </div> */}
                            {/* <hr /> */}
                            <div class="row">
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="opt-conquetes-nb-postal"
                                    data-bs-toggle="tooltip"
                                    title="Number of Postal Sends"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .conquestSection.noPostalSends[language]
                                    }
                                  </label>
                                  <Field
                                    type="text"
                                    name="number_of_postal_sends"
                                    class="form-control"
                                    id="opt-conquetes-nb-postal"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="number_of_postal_sends"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div class="mb-3">
                                  <label
                                    for="ter_cib"
                                    data-bs-toggle="tooltip"
                                    title="Targeted Territory for Postal Sends"
                                    class="form-label"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .conquestSection.targetedTerritory[
                                        language
                                      ]
                                    }
                                  </label>
                                  <Field
                                    type="text"
                                    name="targeted_territory"
                                    class="form-control"
                                    id="ter_cib"
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="targeted_territory"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
}

                    <div class="row" style={{ paddingBottom: 10 }}>
                      <div
                        class={`collapse multi-collapse ${
                          isOpenAll ? "show" : ""
                        }`}
                        id="multiCollapseInfosvente"
                      >
                        <div
                          class="card card-body"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        >
                          <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                            {
                              pageTranslations.formFields.salesInfoSection
                                .title[language]
                            }
                          </h2>
                          <div class="row">
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="titre-de-la-vente"
                                  data-bs-toggle="tooltip"
                                  title="Sale Title"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .saletitle[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="event_title"
                                  class="form-control"
                                  id="titre-de-la-vente"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="event_title"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="vehicule-a-avant"
                                  data-bs-toggle="tooltip"
                                  title="Include the year and model. For microsites, web, banners, letters, etc."
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .featuredVehicle[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="featured_vehicle"
                                  class="form-control"
                                  id="vehicule-a-avant"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="featured_vehicle"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="coup-de-coeur"
                                  data-bs-toggle="tooltip"
                                  title="Favorites for vehicles to be featured"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .favorites[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="favorites"
                                  class="form-control"
                                  id="coup-de-coeur"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="favorites"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="langue-des-visuelles"
                                  data-bs-toggle="tooltip"
                                  title="Visual Language"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .visualLanguage[language]
                                  }
                                </label>

                                <Field
                                  as="select"
                                  class={`form-select ${
                                    isDarkMode ? "custom-select" : ""
                                  }`}
                                  name="visual_language"
                                  aria-label="Default select example"
                                  id="langue-des-visuelles"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                >
                                  <option
                                    value=""
                                    selected=""
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].select[language]}
                                  </option>
                                  <option
                                    value="French"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                              translations.commonWords.french[
                                                language
                                              ]
                                            }
                                  </option>
                                  <option
                                    value="English"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                              translations.commonWords.english[
                                                language
                                              ]
                                            }
                                  </option>
                                  <option
                                    value="Bilingual"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                              translations.commonWords.bilangual[
                                                language
                                              ]
                                            }
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="visual_language"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <hr
                            style={{
                              borderTop: isDarkMode
                                ? "1px solid #ffffff"
                                : "1px solid #000000",
                            }}
                          />

                          <div class="row">
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="plage-hor-des-rdv"
                                  data-bs-toggle="tooltip"
                                  title="Appointment Time Slots"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .appointmentTS[language]
                                  }
                                </label>
                                <Field
                                  as="select"
                                  class={`form-select ${
                                    isDarkMode ? "custom-select" : ""
                                  }`}
                                  name="appointment_time_slots"
                                  aria-label="Default select example"
                                  id="plage-hor-des-rdv"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                >
                                  <option
                                    value=""
                                    selected=""
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].select[language]}
                                  </option>
                                  <option
                                    value="30"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    30
                                  </option>
                                  <option
                                    value="60"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    60
                                  </option>
                                  {/* <option
                                    value="90"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    90
                                  </option> */}
                                  <option
                                    value="120"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    120
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="appointment_time_slots"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="plage-hor-des-rdv"
                                  data-bs-toggle="tooltip"
                                  title="Number of Appointments per Time Slot"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .appointmentsPTS[language]
                                  }
                                </label>
                                <Field
                                  as="select"
                                  class={`form-select ${
                                    isDarkMode ? "custom-select" : ""
                                  }`}
                                  name="appointments_per_time_slot"
                                  aria-label="Default select example"
                                  id="rdv-par-plage-hor"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                >
                                  <option
                                    value=""
                                    selected=""
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].select[language]}
                                  </option>
                                  <option
                                    value="1"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    1
                                  </option>
                                  <option
                                    value="2"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    2
                                  </option>
                                  <option
                                    value="3"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    3
                                  </option>
                                  <option
                                    value="4"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    4
                                  </option>
                                  <option
                                    value="5"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    5
                                  </option>
                                  <option
                                    value="6"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    6
                                  </option>
                                  <option
                                    value="7"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    7
                                  </option>
                                  <option
                                    value="8"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    8
                                  </option>
                                  <option
                                    value="9"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    9
                                  </option>
                                  <option
                                    value="10"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    10
                                  </option>
                                  <option
                                    value="11"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    11
                                  </option>
                                  <option
                                    value="12"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    12
                                  </option>
                                  <option
                                    value="13"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    13
                                  </option>
                                  <option
                                    value="14"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    14
                                  </option>
                                  <option
                                    value="15"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    15
                                  </option>
                                  <option
                              value="16"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              16
                            </option>
                            <option
                              value="17"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              17
                            </option>
                            <option
                              value="18"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              18
                            </option>
                            <option
                              value="19"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              19
                            </option>
                            <option
                              value="20"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              20
                            </option>
                                </Field>
                                <ErrorMessage
                                  name="appointments_per_time_slot"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="heures-ouverture"
                                  data-bs-toggle="tooltip"
                                  title="Indicate the opening and closing hours of the dealership and the last appointment time for an entire week, from Monday to Sunday. Example: Monday - Friday: 10 am - 8 pm (LTA: 7 pm) | Saturday-Sunday: 10 am - 4 pm (LTA: 3 pm)"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .openingHoursWLA[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="opening_hours"
                                  class="form-control"
                                  id="heures-ouverture"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="opening_hours"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="mb-3">
                                <label
                                  for="ach-vec-occ"
                                  data-bs-toggle="tooltip"
                                  title="Are you a buyer of used vehicles without a new purchase or lease from the customer?"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .buyerOfUsedVehicles[language]
                                  }
                                </label>
                                <Field
                                  as="select"
                                  class={`form-select ${
                                    isDarkMode ? "custom-select" : ""
                                  }`}
                                  name="new_purchase_or_lease"
                                  aria-label="Default select example"
                                  id="ach-vec-occ"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                >
                                  <option
                                    value=""
                                    selected="Select"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  ></option>
                                  <option
                                    value="Yes"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].yes[language]}
                                  </option>
                                  <option
                                    value="No"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].no[language]}
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="new_purchase_or_lease"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="mb-3">
                                <label
                                  for="ass-rdv"
                                  data-bs-toggle="tooltip"
                                  title="Do you want to assign your appointments to the customer's representative?"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .appointmentABS[language]
                                  }
                                </label>
                                <Field
                                  as="select"
                                  class={`form-select ${
                                    isDarkMode ? "custom-select" : ""
                                  }`}
                                  name="assignment_by_seller"
                                  aria-label="Default select example"
                                  id="ass-rdv"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                >
                                  <option
                                    value=""
                                    selected=""
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].select[language]}
                                  </option>
                                  <option
                                    value="Assignment"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.assignment[language]
                                    }
                                  </option>
                                  <option
                                    value="No Assignment"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                      pageTranslations.formFields
                                        .salesInfoSection.noAssignment[language]
                                    }
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="assignment_by_seller"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <hr
                            style={{
                              borderTop: isDarkMode
                                ? "1px solid #ffffff"
                                : "1px solid #000000",
                            }}
                          />
                          <div class="row">
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="accueille-les-clients"
                                  data-bs-toggle="tooltip"
                                  title="Who greets customers?"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .greetsCustomers[language]
                                  }
                                </label>
                                <Field
                                  as="select"
                                  class={`form-select ${
                                    isDarkMode ? "custom-select" : ""
                                  }`}
                                  name="greets_customers"
                                  aria-label="Default select example"
                                  id="accueille-les-clients"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                >
                                  <option
                                    value=""
                                    selected=""
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].select[language]}
                                  </option>
                                  <option
                                    value="Event Director"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                      translations["dropdown"].eventDirector[
                                        language
                                      ]
                                    }
                                  </option>
                                  <option
                                    value="Dealership Employee"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                      translations["dropdown"]
                                        .dealershipEmployee[language]
                                    }
                                  </option>
                                  <option
                                    value="Reception"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {
                                      translations["dropdown"].Reception[
                                        language
                                      ]
                                    }
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="greets_customers"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="nom-personne-accueil"
                                  data-bs-toggle="tooltip"
                                  title="The name of the person at the reception"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .nameOfpersonatReception[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="receptionist"
                                  class="form-control"
                                  id="nom-personne-accueil"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="receptionist"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          <hr
                            style={{
                              borderTop: isDarkMode
                                ? "1px solid #ffffff"
                                : "1px solid #000000",
                            }}
                          />

{
  false &&

                          <div class="row">
                            <h4>Balloons showroom</h4>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="qte-ballon"
                                  data-bs-toggle="tooltip"
                                  title="Quantity (number of vehicles in the showroom)"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .quantity[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="balloons_quantity"
                                  class="form-control"
                                  id="qte-ballon"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="balloons_quantity"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="clr-ballon"
                                  data-bs-toggle="tooltip"
                                  title="Colors of balloons for the showroom"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.salesInfoSection
                                      .colors[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="balloons_colors"
                                  class="form-control"
                                  id="clr-ballon"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="balloons_colors"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
}
                        </div>
                      </div>
                    </div>

                    <div class="row" style={{ paddingBottom: 10 }}>
                      <div
                        class={`collapse multi-collapse ${
                          isOpenAll ? "show" : ""
                        }`}
                        id="multiCollapseInfoscontact"
                      >
                        <div
                          class="card card-body"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        >
                          <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                            Contact
                          </h2>

                          <div class="row">
                            <h4>
                              {
                                pageTranslations.formFields.contactSection
                                  .themePromos[language]
                              }
                            </h4>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="rep-theme-promos-nom"
                                  data-bs-toggle="tooltip"
                                  title="Name of the person in charge of the Theme and Promotions"
                                  class="form-label"
                                >
                                  {translations["commonFields"].name[language]}
                                </label>
                                <Field
                                  type="text"
                                  name="contact_tp_name"
                                  class="form-control"
                                  id="rep-theme-promos-nom"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="contact_tp_name"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="rep-theme-promos-courriel"
                                  data-bs-toggle="tooltip"
                                  title="Email of the person in charge of the Theme and Promotions"
                                  class="form-label"
                                >
                                  {translations["commonFields"].email[language]}
                                </label>
                                <Field
                                  type="text"
                                  name="contact_tp_email"
                                  class="form-control"
                                  id="rep-theme-promos-courriel"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="contact_tp_email"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="rep-theme-promos-tel"
                                  data-bs-toggle="tooltip"
                                  title="Phone of the person in charge of the Theme and Promotions"
                                  class="form-label"
                                >
                                  {translations["commonFields"].phone[language]}
                                </label>
                                <Field
                                  type="text"
                                  name="contact_tp_phone"
                                  class="form-control"
                                  id="rep-theme-promos-tel"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="contact_tp_phone"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <h4>
                              {
                                pageTranslations.formFields.contactSection
                                  .lists[language]
                              }
                            </h4>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="rep-listes-nom"
                                  data-bs-toggle="tooltip"
                                  title="Name of the person in charge of the Lists"
                                  class="form-label"
                                >
                                  {translations["commonFields"].name[language]}
                                </label>
                                <Field
                                  type="text"
                                  name="contact_list_name"
                                  class="form-control"
                                  id="rep-listes-nom"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="contact_list_name"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="rep-listes-courriel"
                                  data-bs-toggle="tooltip"
                                  title="Email of the person in charge of the Lists"
                                  class="form-label"
                                >
                                  {translations["commonFields"].email[language]}
                                </label>
                                <Field
                                  type="text"
                                  name="contact_list_email"
                                  class="form-control"
                                  id="rep-listes-courriel"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="contact_list_email"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="rep-listes-tel"
                                  data-bs-toggle="tooltip"
                                  title="Phone of the person in charge of the Lists"
                                  class="form-label"
                                >
                                  {translations["commonFields"].phone[language]}
                                </label>
                                <Field
                                  type="text"
                                  name="contact_list_phone"
                                  class="form-control"
                                  id="rep-listes-tel"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="contact_list_phone"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" style={{ paddingBottom: 10 }}>
                      <div
                        class={`collapse multi-collapse ${
                          isOpenAll ? "show" : ""
                        }`}
                        id="multiCollapseInfosequipe"
                      >
                        <div
                          class="card card-body"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        >
                          <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                            {
                              pageTranslations.formFields.teamSection.title[
                                language
                              ]
                            }
                          </h2>

                          <div class="row">
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="equipe-directeurs"
                                  data-bs-toggle="tooltip"
                                  title="Name of the Director(s)"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.teamSection
                                      .directors[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="directors"
                                  class="form-control"
                                  id="equipe-directeurs"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="directors"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>

                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="equipe-rep"
                                  data-bs-toggle="tooltip"
                                  title="Name of the Representatives"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields.teamSection
                                      .representative[language]
                                  }
                                </label>
                                <Field
                                  type="text"
                                  name="representatives"
                                  class="form-control"
                                  id="equipe-rep"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="representatives"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" style={{ paddingBottom: 10 }}>
                      <div
                        class={`collapse multi-collapse ${
                          isOpenAll ? "show" : ""
                        }`}
                        id="multiCollapseInfospromos"
                      >
                        <div
                          class="card card-body"
                          style={{
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        >
                          <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                            {
                              pageTranslations.formFields.promoAndRequestSection
                                .title[language]
                            }
                          </h2>

                          <div class="row">
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="event-promos"
                                  data-bs-toggle="tooltip"
                                  title="Promotions for the event"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields
                                      .promoAndRequestSection.promotions[
                                      language
                                    ]
                                  }
                                </label>
                                <Field
                                  as="textarea"
                                  type="text"
                                  name="promotions"
                                  class="form-control"
                                  id="event-promos"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                ></Field>
                                <ErrorMessage
                                  name="promotions"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="event-comm"
                                  data-bs-toggle="tooltip"
                                  title="Comments for the event"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields
                                      .promoAndRequestSection.comments[language]
                                  }
                                </label>
                                <Field
                                  as="textarea"
                                  type="text"
                                  name="comments"
                                  class="form-control"
                                  id="event-comm"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                ></Field>
                                <ErrorMessage
                                  name="comments"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="modifSelection"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields
                                      .promoAndRequestSection.q1[language]
                                  }
                                </label>
                                <Field
                                  as="textarea"
                                  type="text"
                                  name="modifications_instructions"
                                  class="form-control"
                                  id="modifSelection"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="modifications_instructions"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-8">
                              <div class="mb-3">
                                <label
                                  for="modeleAnnPrio"
                                  data-bs-toggle="tooltip"
                                  title="Do you have specific models or years that you would like us to prioritize in the selection?"
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields
                                      .promoAndRequestSection.q2[language]
                                  }
                                </label>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="mb-3">
                                <Field
                                  as="select"
                                  class={`form-select ${
                                    isDarkMode ? "custom-select" : ""
                                  }`}
                                  name="models_or_years_selection"
                                  aria-label="Default select example"
                                  id="modeleAnnPrio"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                >
                                  <option
                                    value=""
                                    selected=""
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].select[language]}
                                  </option>
                                  <option
                                    value="Yes"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].yes[language]}
                                  </option>
                                  <option
                                    value="No"
                                    style={{
                                      background: isDarkMode ? "#1B1F22" : "",
                                      color: isDarkMode ? "#ffffff" : "",
                                    }}
                                  >
                                    {translations["dropdown"].no[language]}
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name="models_or_years_selection"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="mb-3">
                                <label
                                  for="modeleAnnPrioOui"
                                  data-bs-toggle="tooltip"
                                  title=""
                                  class="form-label"
                                >
                                  {
                                    pageTranslations.formFields
                                      .promoAndRequestSection.q3[language]
                                  }
                                </label>
                                <Field
                                  as="textarea"
                                  type="text"
                                  name="prioriti_reason"
                                  class="form-control"
                                  id="modeleAnnPrioOui"
                                  style={{
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "",
                                    color: isDarkMode ? "#ffffff" : "#000000",
                                    border: isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6",
                                  }}
                                />
                                <ErrorMessage
                                  name="prioriti_reason"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="footersec">
                    <div class="row">
                      <div class="col-sm" style={{ padding: 7 }}>
                        <button
                          type="submit"
                          class="client-btn btn-success mx-2"
                          onClick={() => setSubmitPressed(true)}
                          id="add-customer-button"
                        >
                          <i class="bi bi-save"></i>{" "}
                          {translations["commonFields"].submitBtn[language]}
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger"
                          onClick={
                            () =>
                              // window.history.pushState({ prevState: 'prev' }, 'title', '/event-portal?page=events')
                              window.history.back()
                            // setDisplayComponent('events')
                          }
                          id="add-customer-button"
                        >
                          <i class="bi bi-save"></i>{" "}
                          {translations["commonFields"].returnBtn[language]}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          {/* <div class="position-fixed top-0 end-0 p-3" style={{ zIndex: "10000000" }}>
            <div id="liveToast" class="toast alert-success" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="d-flex">
                <div class="toast-body">
                  🎊 🎉 Changes saved
                </div>
                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
            <div id="copieToast" class="toast alert-success" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="d-flex">
                <div class="toast-body">
                  🎊 🎉 Copy successful
                </div>
                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>

            <div id="warningToast" class="toast bg-danger" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="d-flex">
                <div class="toast-body" id="warningToastbody">

                </div>
                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
          </div>

          <div id="myModal2" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div id="creationsuccess">
                  <div class="modal-header">
                    <h3 style={{ color: "#000000" }}>🎊 🎉 Document created</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                  </div>
                  <div class="modal-body">
                    <p>Your document has been created successfully. You can download it or send it by email by clicking on the appropriate button below (please select the recipient first).</p>
                    <div class="row">
                      <div class="mb-3">
                        <select class="form-select" id="conq-email">
                          <option></option>
                          <option>mmazraani@fidgi.ca</option>
                          <option>Sylvia.Dimitriou@data-axle.com</option>
                          <option>Sylvia.Dimitriou@data-axle.com</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: "71px" }} class="modal-footer" id="modalfooter">
                    <a type="button" href="" target="”_blank”" class="btn btn-primary" id="doc-download-conq">Download</a>
                    <button type="submit" class="btn btn-primary" id="doc-send-conq">
                      <span id="spinner-doc-send" class="visually-hidden">Loading...</span>
                      Submit the form
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <div class="row" style={{ width: "100%" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                        </symbol>
                        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                        </symbol>
                        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                        </symbol>
                      </svg>
                      <div class="alert alert-danger d-flex align-items-center invisible" id="conqerroralert" role="alert">
                        <div id="conqerroralerttext">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <ModalAddDealer_CreateEvent
            setDealerTrigger={setDealerTrigger}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
